/**
 * Configuration
 * @module config
 */

const config = {
  name: 'User Research  •  Product Strategy  •  Rapid Prototyping  •  Digital Design',
  description: 'People-shaped digital products',
};

export default config;
